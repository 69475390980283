export function getFromStorage(key) {
	if (!key) {
		return null;
	}

	try {
		const valueStr = localStorage.getItem(key);
		if (valueStr) {
			return JSON.parse(valueStr);
		}

		return null;
	} catch (err) {
		return null;
	}
}

export function setInStorage(key, obj) {
	if (process.env.NODE_ENV !== 'production') {
		console.log('Setting in Storage : ', key, obj);
	}
	if (!key) {
		console.error("Error: key is missing");
		return null;
	}

	try {
		localStorage.setItem(key, JSON.stringify(obj));
	} catch (err) {
		console.error(err);
		return null;
	}
}

export function selectImageForDevice(deviceType, data) {
	if (deviceType === "desktop") {
		return data.media.desktop;
	} else if (deviceType === "tablet") {
		return data.media.mobile && data.media.mobile.length !== 0 ? data.media.mobile : data.media.desktop;
	} else if (deviceType === "mobile") {
		return data.media.mobile && data.media.mobile.length !== 0 ? data.media.mobile : data.media.desktop;
	} else {
		return banner.media.desktop;
	}
};


/// TODO: _oldSelectImageForDevice :: Should be deprecated once all properties have moved assets media.
export function _oldSelectImageForDevice(deviceType, data) {
	if (deviceType === "desktop") {
		return data.image;
	} else if (deviceType === "tablet") {
		return data.mobile && data.mobile !== null ? data.mobile : data.image;
	} else if (deviceType === "mobile") {
		//return props.banner.image : isMobile && !Array.isArray(props.banner.mobile) && props.banner.mobile.length !== 0 ? props.banner.mobile : props.banner.image
		return data.mobile && data.mobile !== null ? data.mobile : data.image;
	} else {
		return banner.desktop;
	}
};

export function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function get_browser(userAgent) {
	var ua = userAgent,
		tem,
		M =
			ua.match(
				/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
			) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: "IE", version: tem[1] || "" };
	}
	if (M[1] === "Chrome") {
		tem = ua.match(/\bOPR\/(\d+)/);
		if (tem != null) {
			return { name: "Opera", version: tem[1] };
		}
	}
	if (userAgent.indexOf("Edge") > -1) {
		tem = ua.match(/\Edge\/(\d+)/);
		if (tem != null) {
			return { name: "Edge", version: tem[1] };
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
		M.splice(1, 1, tem[1]);
	}
	return {
		name: M[0],
		version: +M[1],
	};
}

export function isIE(browser) {
	var supported = false;
	if (
		(browser.name === "MSIE" || browser.name === "IE")
	) {
		supported = true;
	}
	return false;
}

export function isInternetExplorer(browser) {
	var _isInternetExplorer = false;
	if (
		(browser.name === "MSIE" || browser.name === "IE")
	) {
		_isInternetExplorer = true;
	}
	return _isInternetExplorer;
}

export function validateData(dataArr) {
	var retVal = true;

	for (let i = 0; i < dataArr.length; i++) {
		const data = dataArr[i];
		if (data.error) {
			retVal = false
		};
	}

	return retVal;
}
