import Head from "next/head";
export default function Wrapper(props) {
	return (
		<div className="main">
			<Head>
				<meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0"
				/>
			</Head>
			{props.children}
		</div>
	);
}