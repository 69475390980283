import Axios from "./config.js";

async function getQueryFnWithType<T>(endpoint: string, token: string | null = "") {
	try {
		const res = await Axios.get(`${endpoint}${token ? `?token=${token}` : ''}`);

		if (res.error) {
			return { error: res.error, data: null };
		} else {
			return { error: null, data: res.data as T };
		}
	} catch (e) {
		console.error(e);
	}

	return { error: "Failed to retrieve data.", data: null };
}

async function getQueryFn(endpoint, token = "") {
	return await Axios.get(`${endpoint}${token ? `?token=${token}` : ''}`).then(res => {
		if (res.error) {
			return { error: res.error }
		}
		return res.data;
	}).catch((err) => {
		console.error(err);
	})
}

async function postQueryFn(endpoint, data) {
	return await Axios.post(`${endpoint}`, data).then(res => {
		if (res.error) {
			return { error: res.error }
		}
		return res.data;
	}).catch((err) => {
		console.error(err);
	})
}

function queryKeyManager(queryKeys, values) {
	let newQueryKeys = queryKeys;

	values.forEach(value => {
		if (value === undefined || value === null || value === "") return;
		if (!newQueryKeys.includes(value)) {
			newQueryKeys.push(value)
		}
	})

	newQueryKeys = newQueryKeys.filter((element) => {
		return element !== undefined;
	});

	newQueryKeys = newQueryKeys.filter((element) => {
		return element !== "";
	});

	return newQueryKeys;
}

export { getQueryFn, postQueryFn, queryKeyManager, getQueryFnWithType };