import React from "react";
import App from "next/app";
import TagManager from "react-gtm-module";

import { Router, withRouter } from "next/router";

import NProgress from "nprogress";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

import { isIE, get_browser } from "../utils/index";

import "../Assets/scss/index.scss";

import SiteContext from "../components/Contexts/SiteContext";
import DataLayerContext from "../components/Contexts/DataLayerContext";
import CookieContext from "../components/Contexts/CookieContext";
import Wrapper from "../components/Layout/Wrapper";

import IncompatablePage from "../components/Pages/IncompatibleBrowserPage"
import UnderMaintenancePage from "../components/Pages/UnderMaintenancePage"

import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const tagManagerArgs = {
	gtmId: publicRuntimeConfig.googleTagManager,
	dataLayerName: "PageDataLayer",
};

NProgress.configure({ showSpinner: false });

// //Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

class MyApp extends App {
	state = {
		canRender: true,
		isUnderMaintenance: publicRuntimeConfig.UNDERGOING_MAINTENANCE,
		overrideUnderMaintenance: false,
		queryClient: new QueryClient()
	};

	componentDidMount() {
		const jssStyles = document.querySelector("#jss-server-side");

		if (jssStyles && jssStyles.parentNode) {
			// ...and if they exist remove them from the head
			jssStyles.parentNode.removeChild(jssStyles);
		}

		if (typeof navigator !== "undefined") {
			let browser = get_browser(navigator.userAgent);
			this.setState({
				canRender: !isIE(browser), // if you are on the client you can access the navigator from the window object
			});
		}

		if (this.props.router.query.overrideUnderMaintenance && this.props.router.query.overrideUnderMaintenance == 'true') {
			this.setState({
				overrideUnderMaintenance: true
			});
		}

		TagManager.initialize(tagManagerArgs);
	}

	render() {
		const { Component, pageProps } = this.props;

		if (this.state.isUnderMaintenance == true || this.state.isUnderMaintenance == "true") {
			// TODO: Add a check for the overrideUnderMaintenance query param
			// if (ctx.query.overrideMaintenance && ctx.query.overrideMaintenance == 'true') {
			// 	overrideMaintenance = true;
			// } else {
			// 	overrideMaintenance = false;
			// }

			return (
				<SiteContext {...pageProps}>
					<Wrapper>
						<UnderMaintenancePage {...pageProps} />
					</Wrapper>
				</SiteContext>
			);
		}

		if (!this.state.canRender) {
			return (
				<IncompatablePage />
			);
		}

		return (
			<QueryClientProvider client={this.state.queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<ReactQueryDevtools initialIsOpen={false} />
					<SiteContext {...pageProps}>
						<DataLayerContext {...pageProps}>
							<CookieContext {...pageProps}>
								<Wrapper >
									<Component {...pageProps} />
								</Wrapper>
							</CookieContext>
						</DataLayerContext>
					</SiteContext>
				</Hydrate>
			</QueryClientProvider>
		);
	}
}

export default withRouter(MyApp);